#designer_new_entry {
  #btn02 {
    &::before,
    &::after {
      content: "新卒・第二新卒デザイナー" !important;
      letter-spacing: 1px;
    }
  }
}
#designer_entry {
  #btn02 {
    &::before,
    &::after {
      content: "グラフィック・WEBデザイナー" !important;
      letter-spacing: 1px;
    }
  }
}
#producer {
  #btn02 {
    &::before,
    &::after {
      content: "WEBプロデューサー" !important;
      letter-spacing: 1px;
    }
  }
}
#director_market_entry {
  #btn02 {
    &::before,
    &::after {
      content: "WEBディレクター" !important;
      letter-spacing: 1px;
    }
  }
}
#director_ui_entry {
  #btn02 {
    &::before,
    &::after {
      content: "UI／UXディレクター" !important;
      letter-spacing: 1px;
    }
  }
}
.line-top {
  position: relative;
  margin-top: 60px;
  padding-top: 60px;
  @include media-query-sp {
    margin-top: 30px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 60px;
    right: 60px;
    background-color: #162c44;
    height: 2px;
    @include media-query-sp {
      left: 0;
      right: 0;
    }
  }
}
.job_category {
  align-items: flex-start;
}
.job_item {
  height: inherit !important;
}
.btn::before,
.btn::after {
  margin-left: 0;
}
//entry page
.entry.has-job .job_category-container {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -30px;
    width: 100%;
    height: 1px;
    left: 0;
    background-color: #b9b9b9;
  }
}
.entry.info {
  h1, h2, h3, h4, h5, p, form, dl, ul, input, textarea {
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
    color: #162c44;
  }
  .contents {
    @include media-query-sp {
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
    }
  }
  .txt_birth {
    @include media-query-sp {
      margin-left: 2px;
      margin-right: 10px;
    }
  }
  .input_itemSS {
    @include media-query-sp {
      padding: 8px 9px;
    }
  }
}
.contents--entry {
  overflow: hidden;
  @include media-query-sp {
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
  }
  img {
    @include media-query-sp {
      max-width: 100%;
    }
  }
  .align_center {
    text-align: center;
  }
}
.entry-main-image {
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-shadow: 0px 0px 7.28px rgba(22, 44, 68, 0.7);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 380px;
    color: #fff;
    @include media-query-sp {
      padding: 30px 0;
      height: inherit;
    }
    &.director-market {
      background-image: url(../entry/images/director01_img01.jpg);
      @include media-query-sp {
        background-image: url(../entry/images/director01_img01_sp.jpg);
      }
    }
    &.director-ui {
      background-image: url(../entry/images/director02_img01.jpg);
      @include media-query-sp {
        background-image: url(../entry/images/director02_img01_sp.jpg);
        background-position: center center;
      }
    }
    &.designer {
      background-image: url(../entry/images/designer01_img01.jpg);
      @include media-query-sp {
        background-image: url(../entry/images/designer01_img01_sp.jpg);
      }
    }
    &.designer-new {
      background-image: url(../entry/images/designer02_img01.jpg);
      @include media-query-sp {
        background-image: url(../entry/images/designer02_img01_sp.jpg);
      }
    }
  }
}
.entry-main-text {
  margin: 0;
  font-size: (2.2/1.6)+rem;
  line-height: (48/22);
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
  color: #fff !important;
  @include media-query-sp {
    font-size: 1rem;
    line-height: (50/32);
    letter-spacing: 0;
  }
}
/* モジュール */
.m-section01 {
  margin-top: 100px;
  width: 100%;
  @include media-query-sp {
    margin-top: 50px;
  }
  &:first-child {
    margin-top: 0;
  }
}
.m-section02 {
  margin-top: 60px;
  @include media-query-sp {
    margin-top: 40px;
  }
  &:first-child {
    margin-top: 0;
  }
}
.m-body01 {
  padding: 60px 60px 0;
  @include media-query-sp {
    padding: 30px 0 0;
  }
}
.m-body02 {
  padding: 60px 0 0;
  @include media-query-sp {
    padding: 30px 0 0;
  }
}
.m-body03 {
  padding: 30px 0 0;
}
.m-title01 {
  display: block;
  background-color: #eaff35;
  margin: 0;
  padding: (12-28*(48/28-1)/2)+px 0;
  font-size: (2.8/1.6)+rem;
  line-height: (48/28);
  font-weight: normal;
  text-align: center;
  font-feature-settings: "palt" 1;
  @include media-query-sp {
    padding: (9-19*0.3/2)+px 0;
    font-size: (1.9/1.6)+rem;
    line-height: 1.3;
  }
}
.m-title02 {
  display: block;
  margin: (-24*(48/28-1)/2)+px 0 0;
  font-size: (2.4/1.6)+rem;
  line-height: (48/28);
  text-align: center;
  font-feature-settings: "palt" 1;
}
.m-title03 {
  display: block;
  margin: 0;
  border-top: 2px solid #eaff35;
  border-bottom: 2px solid #eaff35;
  padding: (12-22*(32/22-1)/2)+px 0;
  font-size: (2.2/1.6)+rem;
  line-height: (32/22);
  text-align: center;
  font-feature-settings: "palt" 1;
  @include media-query-sp {
    padding: (9-19*0.3/2)+px 0;
    font-size: (1.5/1.6)+rem;
    line-height: 1.3;
  }
}
.m-title04 {
  margin: (40-18*(32/18-1)/2)+px 0 (-18*(32/18-1)/2)+px;
  font-size: (1.8/1.6)+rem;
  line-height: (32/18);
  font-feature-settings: "palt" 1;
  @include media-query-sp {
    margin: (30-14*0.3/2)+px 0 (-14*0.3/2)+px;
    font-size: (1.4/1.6)+rem;
    line-height: 1.3;
  }
}
.m-title-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 16px;
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../entry/images/dot.png) repeat-x center top;
    height: 1px;
    @include media-query-sp {
      background-size: (706/2)+px 1px;
    }
  }
  > .icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #162c44;
    border-radius: 100%;
    width: 56px;
    height: 56px;
    line-height: 1;
    font-weight: normal;
    letter-spacing: 0;
    .icon-text {
      display: block;
      padding-top: 2px;
      width: 100%;
      font-size: (1.2/1.6)+rem;
      color: #fff;
      text-align: center;
      &.letter2 {
        margin-left: 3px;
        letter-spacing: 6px;
      }
      &.letter4 {
        margin-left: 1px;
        letter-spacing: -1.4px;
      }
    }
    .icon-num {
      display: block;
      margin-top: 3px;
      width: 100%;
      font-size: (2.2/1.6)+rem;
      color: #fff;
      text-align: center;
    }
  }
  > .text {
    flex: 1;
    padding-left: 20px;
    font-size: (1.8/1.6)+rem;
    font-weight: normal;
    line-height: 1.6;
    @include media-query-sp {
      padding-left: 10px;
      font-size: 1rem;
      letter-spacing: 0;
    }
  }
}
.m-text01 {
  margin: (40-16*(32/16-1)/2)+px 0 (-16*(32/16-1)/2)+px;
  line-height: (3.2/1.6);
  letter-spacing: .1px;
  @include media-query-sp {
    margin: (30-14*(56/28-1)/2)+px 0 (-14*(56/28-1)/2)+px;
    font-size: (1.4/1.6)+rem;
    line-height: (56/28);
  }
  &:first-child {
    margin-top: (-16*(32/16-1)/2)+px;
    @include media-query-sp {
      margin-top: (-14*(32/16-1)/2)+px;
    }
  }
  strong {
    background-color: rgba(#eaff35,0.7);
    margin: 0 3px;
    font-weight: normal;
  }
}
.m-text02 {
  margin: (20-14*(32/16-1)/2)+px 0 (-14*(32/16-1)/2)+px;
  font-size: (1.4/1.6)+rem;
  line-height: (3.2/1.6);
  letter-spacing: .1px;
  @include media-query-sp {
    margin: (10-12*(56/28-1)/2)+px 0 (-12*(56/28-1)/2)+px;
    font-size: (1.2/1.6)+rem;
    line-height: (56/28);
  }
  &:first-child {
    margin-top: (-14*(32/16-1)/2)+px;
    @include media-query-sp {
      margin-top: (-12*(56/28-1)/2)+px;
    }
  }
}
.m-text_link {
  text-decoration: underline;
  color: #4191e5;
  @include media-query-sp {

  }
  &:hover {
    text-decoration: none;
  }
}
.m-image01 {
  margin-top: 90px;
  @include media-query-sp {
    margin-top: 60px;
    width: 100%;
  }
}
/* main下のポイントリスト */
.entry-point-tips {
  overflow: hidden;
  margin: 10px -5px 0;
  padding: 0;
  width: 100%;
  list-style: none;
  line-height: 1;
  > li {
    display: inline-block;
    position: relative;
    background-color: #162c44;
    margin: 10px 5px 0;
    border-radius: 12px;
    padding: 5px 10px;
    color: #fff;
    font-size: (1.4/1.6)+rem;
    line-height: 1;
    font-feature-settings: "palt" 1;
  }
}
/* Dir用チェックリスト */
.entry-check-list {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    position: relative;
    padding: (40-18*(52/32-1)/2)+px 0 (40-18*(52/32-1)/2)+px 60px;
    font-size: (1.8/1.6)+rem;
    line-height: (52/32);
    @include media-query-sp {
      padding: (25-16*(52/32-1)/2)+px 0 (25-16*(52/32-1)/2)+px 30px;
      font-size: 1rem;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: (40-18*(52/32-1)/2/2)+px;
      left: 0;
      background: url(../entry/images/icon_check.png) no-repeat center center;
      background-size: 100%;
      border: 1px solid #162c44;
      width: 20px;
      height: 20px;
      @include media-query-sp {
        top: 23px;
        width: (34/2)+px;
        height: (34/2)+px;
      }
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: url(../entry/images/dot.png) repeat-x center top;
      height: 1px;
    }
    &:first-child {
      padding-top: 0;
      &:before {
        top: (18*(52/32-1)/2/2)+px;
        @include media-query-sp {
          top: (16*(52/32-1)/2-2)+px;
        }
      }
    }
  }
}
/* workリスト */
.entry-work-list {
  margin: -20px -10px 0;
  padding: 0;
  list-style: none;
  @include media-query-sp {
    margin: -10px -5px 0;
  }
  > li {
    display: inline-block;
    margin: 20px 10px 0;
    border-radius: 4px;
    border: 2px solid #162c44;
    padding: 10px 20px;
    font-size: (1.8/1.6)+rem;
    font-weight: bold;
    color: #162c44;
    line-height: 1;
    letter-spacing: 0;
    @include media-query-sp {
      margin: 10px 5px 0;
      border-width: 1px;
      border-radius: 2px;
      padding: 10px;
      font-size: (1.5/1.6)+rem;
    }
  }
}
/* interview */
.entry-interview-profile {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 230px 1fr;
  @include media-query-sp {
    display: block;
  }
  &-image {
    background-size: cover;
    background-repeat: no-repeat;
    grid-column: 1;
    grid-row: 1 / 3;
    margin-right: 10px;
    @include media-query-sp {
      margin: 0 auto;
      width: (264/2)+px;
      height: (264/2)+px;
    }
    &--designer {
      background-image: url(../entry/images/interview01_prof.png);
    }
    &--director {
      background-image: url(../entry/images/interview02_prof.png);
    }
  }
  &-text {
    grid-column: 1;
    grid-row: 1;
    flex: 1;
  }
  .text01 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: rgba(22, 44, 68, 0.08);
    margin: 0;
    padding: 20px 30px;
    @include media-query-sp {
      margin-top: 10px;
      padding: 15px;
    }
    .text01-post {
      display: block;
      width: 100%;
      font-size: (2.2/1.6)+rem;
      font-weight: bold;
      line-height: 1;
      @include media-query-sp {
        font-size: (1.8/1.6)+rem;
      }
    }
    .text01-time {
      display: block;
      width: 100%;
      margin-top: 15px;
      line-height: 1;
      font-weight: bold;
      @include media-query-sp {
        margin-top: 10px;
        font-size: (1.2/1.6)+rem;
      }
    }
  }
  .text02 {
    background-color:  rgba(22, 44, 68, 0.08);
    margin: 5px 0 0;
    padding: 20px 30px;
    line-height: (3.2/1.6);
    @include media-query-sp {
      padding: 10px 15px;
      font-size: (1.3/1.6)+rem;
      line-height: (46/26);
    }
  }
}
/* memberリスト */
.entry-member-list {
  margin: 0;
  padding: 0;
  list-style: none;
  > li {
    display: flex;
    align-items: center;
    margin: 40px 0 0;
    padding: 0;
    width: 100%;
    @include media-query-sp {
      margin-top: 30px;
      align-items: flex-start;
    }
    &:first-child {
      margin-top: 0;
    }
    .image {
      width: 110px;
      @include media-query-sp {
        width: (198/2)+px;
      }
      img {
        width: 100%;
      }
    }
    .text-container {
      flex: 1;
      padding: 0 20px;
      @include media-query-sp {
        padding: 0 0 0 15px;
      }
      .m-text01 {
        @include media-query-sp {
          font-size: (1.4/1.6)+rem;
          letter-spacing: 0;
        }
      }
    }
  }
}
.entry-detail {
  * {
    box-sizing: border-box;
  }
  @include media-query-sp {
    font-size: (1.4/1.6)+rem;
    line-height: (56/28);
  }
}
/***************************************************************/
/************************* job *****************************/
/***************************************************************/
.job_category-container {
  box-sizing: border-box;
}
body.info div#main {
  box-sizing: border-box;
  min-height: calc(100vh - 174px);
  @include media-query-sp {
    min-height: inherit;
  }
}

/* 職種別ボタン */
.job_category{
  text-align:center;
  vertical-align: middle;
  list-style-type:none;
  padding:0;
  margin-top: 20px;
  margin-bottom:120px;
}
.job_item > h3{
  color: #000;
  font-size: 1.75em;
  height: 1.75em;
  letter-spacing: 0.525em;
  margin-left: 0.525em;
  margin-top: 80px;
  margin-bottom: 40px;
}
.job_item > p{
  font-size: 1.125em;
  font-weight: bold;
  color: #626262;
  letter-spacing:  0.1em !important;
  // margin-left: 0.16875em;
  margin-top: -9px;
  line-height: 30px;
}
@media screen and (min-width:1024px){
  .job_item > p{
      line-height: 30px;
  }
}
@media screen and (max-width: 1023px) {
  .job_category{
      margin-bottom: 60px;
  }
  .job_item > h3{
      margin-top: 60px;
      margin-bottom: 20px;
  }
  .job_item{
      height: 200px;
  }
  .btnlist{
      margin-bottom: 20px;
  }
}
.btn{
  text-align: left;
  letter-spacing: 0;
  /*文字*/
  text-decoration: none;
  color: black;
  /*アニメーション*/
  transition: all 0.3s;
}
#btn02::before, #btn02::after{
  content: "ディレクター募集要項";
}
#btn03::before, #btn03::after {
  content: "インターン募集を見る";
}
#btn04::before, #btn04::after {
  content: "アルバイト募集を見る";
}
.btn::before, .btn::after {
  /*文字*/
  font-size: 1.125em;
  font-weight: bold;
  color: #000;
  letter-spacing:calc(1.125em*0.3);
  // margin-left: calc(-1.125em*0.3*0.5);
  text-decoration: none;
  text-align:center;
  vertical-align: middle;
  letter-spacing: 1px;
  line-height: 70px;
  /*サイズとレイアウト*/
  position: absolute;
  transition: all 0.3s;
  z-index: 1;
}
.btn::before {
  opacity: 0;
  background-color: #eaff35;/*背景色*/
  transform: scale(1, 0.1);
  border: thin white solid;
}
.btn:hover::before {
  opacity: 1;
  transform: scale(1, 1);
  border: thin #000000 solid;/*輪郭線*/
  background: rgba(255, 255, 255, 0.1);
  z-index: -2;
}
.btn::after {
  opacity: 1;
  transition: all 0.3s;
  background-color: #eaff35;/*背景色*/
  border: thin #000000 solid;/*輪郭線*/
}
.btn:hover::after {
  transform: scale(1, .1);
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  border: thin white solid;/*輪郭線*/
}



@media screen and (max-width: 379px) {
  .job_item > h3{
      font-size:22px;
      height: 1.75em;
      letter-spacing:calc(22px*0.3);
      // margin-left: calc(22px*0.3*0.5);
  }
  .job_item > p, .btn::before, .btn::after {
      font-size:1em;
      letter-spacing:calc(1em*0.3);
      // margin-left: calc(1em*0.3*0.5);
  }
  @media screen and (max-width: 290px) {
  .job_item > p, .btn::before, .btn::after {
          font-size:0.8em;
          letter-spacing:calc(0.8em*0.3);
          // margin-left: calc(0.8em*0.3*0.5);
      }
  }
  .job_category > li{
      width: 100%;
  }
  .job_category li a{
      width: 100%;
      margin-right: 0;
      margin-left: 0;
  }
  .btn{
      width: 100%; height: 70px;
  }
  .btn::before, .btn::after{
      width: calc(100% - 20px);
  }
}
@media screen and (min-width:380px)  and (max-width: 479px) {
  .job_item > h3{
      font-size:22px;
      height: 1.75em;
      letter-spacing:calc(22px*0.3);
      // margin-left: calc(22px*0.3*0.5);
  }
  .job_item > p, .btn::before, .btn::after {
      font-size:1em;
      letter-spacing:calc(1em*0.3);
      // margin-left: calc(1em*0.3*0.5);
  }
  @media screen and (max-width: 290px) {
  .job_item > p, .btn::before, .btn::after {
          font-size:0.8em;
          letter-spacing:calc(0.8em*0.3);
          // margin-left: calc(0.8em*0.3*0.5);
      }
  }
  .job_category > li{
      width: 100%;
  }
  .btn, .btn::before, .btn::after{
      width: 329px; height: 70px;
  }
  .btn{
      // margin-right: calc((100% - 329px)/2);
      // margin-left: calc((100% - 329px)/2);
  }
}

@media screen and (min-width:480px) and (max-width: 767px) {
  .job_category > li{
      width: 100%;
  }
  .btn, .btn::before, .btn::after{
      width: 329px; height: 70px;
  }
  .btn{
      // margin-right: calc((100% - 329px)/2);
      // margin-left: calc((100% - 329px)/2);
  }
  .job_item > p, .btn::before, .btn::after {
      font-size:1.1em;
      letter-spacing:calc(1.1em*0.3);
      // margin-left: calc(1.1em*0.3*0.5);
  }
}

@media screen and (min-width:768px) and (max-width:1023px){
  .job_category > li{
      width: 100%;
  }
  .btn, .btn::before, .btn::after{
      width: 329px; height: 70px;
  }
  .btn{
      // margin-right: calc((100% - 329px)/2);
      // margin-left: calc((100% - 329px)/2);
  }
  .job_item > p, .btn::before, .btn::after {
      font-size:1.125em;
      letter-spacing:calc(1.125em*0.3);
      // margin-left: calc(1.125em*0.3*0.5);
  }
}
@media screen and (min-width:1024px){
  .job_category > li{
      width: 50%;
  }
  .job_item > p{
      width: 100%; margin:0;
  }
  .btn{
      width: 329px; height: 70px;
      // margin-left: calc((100% - 329px)/2);
      // margin-right: calc((100% - 329px)/2);
  }
  .btn::before, .btn::after{
      width: 329px; height: 70px;
  }
  .job_item > p, .btn::before, .btn::after {
      font-size:1.125em;
      letter-spacing:calc(1.125em*0.3);
      // margin-left: calc(1.125em*0.3*0.5);
  }
}


/***************** none animation for Safari *******************/
_::-webkit-full-page-media, _:future, :root .btn:hover::after, .btn:hover::before{
  transform:none;
}
_::-webkit-full-page-media, _:future, :root .btn::before, .btn::after{
  transition:none;
}
