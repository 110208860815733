@charset "UTF-8";
@font-face {
  font-family: "YuGothic M";
  src: local("Yu Gothic Medium");
}

#designer_new_entry #btn02::before, #designer_new_entry #btn02::after {
  content: "新卒・第二新卒デザイナー" !important;
  letter-spacing: 1px;
}

#designer_entry #btn02::before, #designer_entry #btn02::after {
  content: "グラフィック・WEBデザイナー" !important;
  letter-spacing: 1px;
}

#producer #btn02::before, #producer #btn02::after {
  content: "WEBプロデューサー" !important;
  letter-spacing: 1px;
}

#director_market_entry #btn02::before, #director_market_entry #btn02::after {
  content: "WEBディレクター" !important;
  letter-spacing: 1px;
}

#director_ui_entry #btn02::before, #director_ui_entry #btn02::after {
  content: "UI／UXディレクター" !important;
  letter-spacing: 1px;
}

.line-top {
  position: relative;
  margin-top: 60px;
  padding-top: 60px;
}

@media screen and (max-width: 767px) {
  .line-top {
    margin-top: 30px;
  }
}

.line-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  right: 60px;
  background-color: #162c44;
  height: 2px;
}

@media screen and (max-width: 767px) {
  .line-top:before {
    left: 0;
    right: 0;
  }
}

.job_category {
  align-items: flex-start;
}

.job_item {
  height: inherit !important;
}

.btn::before,
.btn::after {
  margin-left: 0;
}

.entry.has-job .job_category-container {
  position: relative;
}

.entry.has-job .job_category-container:before {
  content: "";
  position: absolute;
  top: -30px;
  width: 100%;
  height: 1px;
  left: 0;
  background-color: #b9b9b9;
}

.entry.info h1, .entry.info h2, .entry.info h3, .entry.info h4, .entry.info h5, .entry.info p, .entry.info form, .entry.info dl, .entry.info ul, .entry.info input, .entry.info textarea {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", sans-serif;
  color: #162c44;
}

@media screen and (max-width: 767px) {
  .entry.info .contents {
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .entry.info .txt_birth {
    margin-left: 2px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .entry.info .input_itemSS {
    padding: 8px 9px;
  }
}

.contents--entry {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .contents--entry {
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .contents--entry img {
    max-width: 100%;
  }
}

.contents--entry .align_center {
  text-align: center;
}

.entry-main-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-shadow: 0px 0px 7.28px rgba(22, 44, 68, 0.7);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 380px;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .entry-main-image-container {
    padding: 30px 0;
    height: inherit;
  }
}

.entry-main-image-container.director-market {
  background-image: url(../entry/images/director01_img01.jpg);
}

@media screen and (max-width: 767px) {
  .entry-main-image-container.director-market {
    background-image: url(../entry/images/director01_img01_sp.jpg);
  }
}

.entry-main-image-container.director-ui {
  background-image: url(../entry/images/director02_img01.jpg);
}

@media screen and (max-width: 767px) {
  .entry-main-image-container.director-ui {
    background-image: url(../entry/images/director02_img01_sp.jpg);
    background-position: center center;
  }
}

.entry-main-image-container.designer {
  background-image: url(../entry/images/designer01_img01.jpg);
}

@media screen and (max-width: 767px) {
  .entry-main-image-container.designer {
    background-image: url(../entry/images/designer01_img01_sp.jpg);
  }
}

.entry-main-image-container.designer-new {
  background-image: url(../entry/images/designer02_img01.jpg);
}

@media screen and (max-width: 767px) {
  .entry-main-image-container.designer-new {
    background-image: url(../entry/images/designer02_img01_sp.jpg);
  }
}

.entry-main-text {
  margin: 0;
  font-size: 1.375rem;
  line-height: 2.18182;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0;
  color: #fff !important;
}

@media screen and (max-width: 767px) {
  .entry-main-text {
    font-size: 1rem;
    line-height: 1.5625;
    letter-spacing: 0;
  }
}

/* モジュール */
.m-section01 {
  margin-top: 100px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .m-section01 {
    margin-top: 50px;
  }
}

.m-section01:first-child {
  margin-top: 0;
}

.m-section02 {
  margin-top: 60px;
}

@media screen and (max-width: 767px) {
  .m-section02 {
    margin-top: 40px;
  }
}

.m-section02:first-child {
  margin-top: 0;
}

.m-body01 {
  padding: 60px 60px 0;
}

@media screen and (max-width: 767px) {
  .m-body01 {
    padding: 30px 0 0;
  }
}

.m-body02 {
  padding: 60px 0 0;
}

@media screen and (max-width: 767px) {
  .m-body02 {
    padding: 30px 0 0;
  }
}

.m-body03 {
  padding: 30px 0 0;
}

.m-title01 {
  display: block;
  background-color: #eaff35;
  margin: 0;
  padding: 2px 0;
  font-size: 1.75rem;
  line-height: 1.71429;
  font-weight: normal;
  text-align: center;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 767px) {
  .m-title01 {
    padding: 6.15px 0;
    font-size: 1.1875rem;
    line-height: 1.3;
  }
}

.m-title02 {
  display: block;
  margin: -8.57143px 0 0;
  font-size: 1.5rem;
  line-height: 1.71429;
  text-align: center;
  font-feature-settings: "palt" 1;
}

.m-title03 {
  display: block;
  margin: 0;
  border-top: 2px solid #eaff35;
  border-bottom: 2px solid #eaff35;
  padding: 7px 0;
  font-size: 1.375rem;
  line-height: 1.45455;
  text-align: center;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 767px) {
  .m-title03 {
    padding: 6.15px 0;
    font-size: 0.9375rem;
    line-height: 1.3;
  }
}

.m-title04 {
  margin: 33px 0 -7px;
  font-size: 1.125rem;
  line-height: 1.77778;
  font-feature-settings: "palt" 1;
}

@media screen and (max-width: 767px) {
  .m-title04 {
    margin: 27.9px 0 -2.1px;
    font-size: 0.875rem;
    line-height: 1.3;
  }
}

.m-title-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: 16px;
}

.m-title-icon:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../entry/images/dot.png) repeat-x center top;
  height: 1px;
}

@media screen and (max-width: 767px) {
  .m-title-icon:after {
    background-size: 353px 1px;
  }
}

.m-title-icon > .icon {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #162c44;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  line-height: 1;
  font-weight: normal;
  letter-spacing: 0;
}

.m-title-icon > .icon .icon-text {
  display: block;
  padding-top: 2px;
  width: 100%;
  font-size: 0.75rem;
  color: #fff;
  text-align: center;
}

.m-title-icon > .icon .icon-text.letter2 {
  margin-left: 3px;
  letter-spacing: 6px;
}

.m-title-icon > .icon .icon-text.letter4 {
  margin-left: 1px;
  letter-spacing: -1.4px;
}

.m-title-icon > .icon .icon-num {
  display: block;
  margin-top: 3px;
  width: 100%;
  font-size: 1.375rem;
  color: #fff;
  text-align: center;
}

.m-title-icon > .text {
  flex: 1;
  padding-left: 20px;
  font-size: 1.125rem;
  font-weight: normal;
  line-height: 1.6;
}

@media screen and (max-width: 767px) {
  .m-title-icon > .text {
    padding-left: 10px;
    font-size: 1rem;
    letter-spacing: 0;
  }
}

.m-text01 {
  margin: 32px 0 -8px;
  line-height: 2;
  letter-spacing: .1px;
}

@media screen and (max-width: 767px) {
  .m-text01 {
    margin: 23px 0 -7px;
    font-size: 0.875rem;
    line-height: 2;
  }
}

.m-text01:first-child {
  margin-top: -8px;
}

@media screen and (max-width: 767px) {
  .m-text01:first-child {
    margin-top: -7px;
  }
}

.m-text01 strong {
  background-color: rgba(234, 255, 53, 0.7);
  margin: 0 3px;
  font-weight: normal;
}

.m-text02 {
  margin: 13px 0 -7px;
  font-size: 0.875rem;
  line-height: 2;
  letter-spacing: .1px;
}

@media screen and (max-width: 767px) {
  .m-text02 {
    margin: 4px 0 -6px;
    font-size: 0.75rem;
    line-height: 2;
  }
}

.m-text02:first-child {
  margin-top: -7px;
}

@media screen and (max-width: 767px) {
  .m-text02:first-child {
    margin-top: -6px;
  }
}

.m-text_link {
  text-decoration: underline;
  color: #4191e5;
}

.m-text_link:hover {
  text-decoration: none;
}

.m-image01 {
  margin-top: 90px;
}

@media screen and (max-width: 767px) {
  .m-image01 {
    margin-top: 60px;
    width: 100%;
  }
}

/* main下のポイントリスト */
.entry-point-tips {
  overflow: hidden;
  margin: 10px -5px 0;
  padding: 0;
  width: 100%;
  list-style: none;
  line-height: 1;
}

.entry-point-tips > li {
  display: inline-block;
  position: relative;
  background-color: #162c44;
  margin: 10px 5px 0;
  border-radius: 12px;
  padding: 5px 10px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1;
  font-feature-settings: "palt" 1;
}

/* Dir用チェックリスト */
.entry-check-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-check-list > li {
  position: relative;
  padding: 34.375px 0 34.375px 60px;
  font-size: 1.125rem;
  line-height: 1.625;
}

@media screen and (max-width: 767px) {
  .entry-check-list > li {
    padding: 20px 0 20px 30px;
    font-size: 1rem;
  }
}

.entry-check-list > li:before {
  content: "";
  display: block;
  position: absolute;
  top: 37.1875px;
  left: 0;
  background: url(../entry/images/icon_check.png) no-repeat center center;
  background-size: 100%;
  border: 1px solid #162c44;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 767px) {
  .entry-check-list > li:before {
    top: 23px;
    width: 17px;
    height: 17px;
  }
}

.entry-check-list > li:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(../entry/images/dot.png) repeat-x center top;
  height: 1px;
}

.entry-check-list > li:first-child {
  padding-top: 0;
}

.entry-check-list > li:first-child:before {
  top: 2.8125px;
}

@media screen and (max-width: 767px) {
  .entry-check-list > li:first-child:before {
    top: 3px;
  }
}

/* workリスト */
.entry-work-list {
  margin: -20px -10px 0;
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 767px) {
  .entry-work-list {
    margin: -10px -5px 0;
  }
}

.entry-work-list > li {
  display: inline-block;
  margin: 20px 10px 0;
  border-radius: 4px;
  border: 2px solid #162c44;
  padding: 10px 20px;
  font-size: 1.125rem;
  font-weight: bold;
  color: #162c44;
  line-height: 1;
  letter-spacing: 0;
}

@media screen and (max-width: 767px) {
  .entry-work-list > li {
    margin: 10px 5px 0;
    border-width: 1px;
    border-radius: 2px;
    padding: 10px;
    font-size: 0.9375rem;
  }
}

/* interview */
.entry-interview-profile {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 230px 1fr;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile {
    display: block;
  }
}

.entry-interview-profile-image {
  background-size: cover;
  background-repeat: no-repeat;
  grid-column: 1;
  grid-row: 1 / 3;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile-image {
    margin: 0 auto;
    width: 132px;
    height: 132px;
  }
}

.entry-interview-profile-image--designer {
  background-image: url(../entry/images/interview01_prof.png);
}

.entry-interview-profile-image--director {
  background-image: url(../entry/images/interview02_prof.png);
}

.entry-interview-profile-text {
  grid-column: 1;
  grid-row: 1;
  flex: 1;
}

.entry-interview-profile .text01 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(22, 44, 68, 0.08);
  margin: 0;
  padding: 20px 30px;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile .text01 {
    margin-top: 10px;
    padding: 15px;
  }
}

.entry-interview-profile .text01 .text01-post {
  display: block;
  width: 100%;
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile .text01 .text01-post {
    font-size: 1.125rem;
  }
}

.entry-interview-profile .text01 .text01-time {
  display: block;
  width: 100%;
  margin-top: 15px;
  line-height: 1;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile .text01 .text01-time {
    margin-top: 10px;
    font-size: 0.75rem;
  }
}

.entry-interview-profile .text02 {
  background-color: rgba(22, 44, 68, 0.08);
  margin: 5px 0 0;
  padding: 20px 30px;
  line-height: 2;
}

@media screen and (max-width: 767px) {
  .entry-interview-profile .text02 {
    padding: 10px 15px;
    font-size: 0.8125rem;
    line-height: 1.76923;
  }
}

/* memberリスト */
.entry-member-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-member-list > li {
  display: flex;
  align-items: center;
  margin: 40px 0 0;
  padding: 0;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .entry-member-list > li {
    margin-top: 30px;
    align-items: flex-start;
  }
}

.entry-member-list > li:first-child {
  margin-top: 0;
}

.entry-member-list > li .image {
  width: 110px;
}

@media screen and (max-width: 767px) {
  .entry-member-list > li .image {
    width: 99px;
  }
}

.entry-member-list > li .image img {
  width: 100%;
}

.entry-member-list > li .text-container {
  flex: 1;
  padding: 0 20px;
}

@media screen and (max-width: 767px) {
  .entry-member-list > li .text-container {
    padding: 0 0 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .entry-member-list > li .text-container .m-text01 {
    font-size: 0.875rem;
    letter-spacing: 0;
  }
}

.entry-detail * {
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .entry-detail {
    font-size: 0.875rem;
    line-height: 2;
  }
}

/***************************************************************/
/************************* job *****************************/
/***************************************************************/
.job_category-container {
  box-sizing: border-box;
}

body.info div#main {
  box-sizing: border-box;
  min-height: calc(100vh - 174px);
}

@media screen and (max-width: 767px) {
  body.info div#main {
    min-height: inherit;
  }
}

/* 職種別ボタン */
.job_category {
  text-align: center;
  vertical-align: middle;
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 120px;
}

.job_item > h3 {
  color: #000;
  font-size: 1.75em;
  height: 1.75em;
  letter-spacing: 0.525em;
  margin-left: 0.525em;
  margin-top: 80px;
  margin-bottom: 40px;
}

.job_item > p {
  font-size: 1.125em;
  font-weight: bold;
  color: #626262;
  letter-spacing: 0.1em !important;
  margin-top: -9px;
  line-height: 30px;
}

@media screen and (min-width: 1024px) {
  .job_item > p {
    line-height: 30px;
  }
}

@media screen and (max-width: 1023px) {
  .job_category {
    margin-bottom: 60px;
  }
  .job_item > h3 {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .job_item {
    height: 200px;
  }
  .btnlist {
    margin-bottom: 20px;
  }
}

.btn {
  text-align: left;
  letter-spacing: 0;
  /*文字*/
  text-decoration: none;
  color: black;
  /*アニメーション*/
  transition: all 0.3s;
}

#btn02::before, #btn02::after {
  content: "ディレクター募集要項";
}

#btn03::before, #btn03::after {
  content: "インターン募集を見る";
}

#btn04::before, #btn04::after {
  content: "アルバイト募集を見る";
}

.btn::before, .btn::after {
  /*文字*/
  font-size: 1.125em;
  font-weight: bold;
  color: #000;
  letter-spacing: calc(1.125em*0.3);
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 1px;
  line-height: 70px;
  /*サイズとレイアウト*/
  position: absolute;
  transition: all 0.3s;
  z-index: 1;
}

.btn::before {
  opacity: 0;
  background-color: #eaff35;
  /*背景色*/
  transform: scale(1, 0.1);
  border: thin white solid;
}

.btn:hover::before {
  opacity: 1;
  transform: scale(1, 1);
  border: thin #000000 solid;
  /*輪郭線*/
  background: rgba(255, 255, 255, 0.1);
  z-index: -2;
}

.btn::after {
  opacity: 1;
  transition: all 0.3s;
  background-color: #eaff35;
  /*背景色*/
  border: thin #000000 solid;
  /*輪郭線*/
}

.btn:hover::after {
  transform: scale(1, 0.1);
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  border: thin white solid;
  /*輪郭線*/
}

@media screen and (max-width: 379px) {
  .job_item > h3 {
    font-size: 22px;
    height: 1.75em;
    letter-spacing: calc(22px*0.3);
  }
  .job_item > p, .btn::before, .btn::after {
    font-size: 1em;
    letter-spacing: calc(1em*0.3);
  }
}

@media screen and (max-width: 379px) and (max-width: 290px) {
  .job_item > p, .btn::before, .btn::after {
    font-size: 0.8em;
    letter-spacing: calc(0.8em*0.3);
  }
}

@media screen and (max-width: 379px) {
  .job_category > li {
    width: 100%;
  }
  .job_category li a {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .btn {
    width: 100%;
    height: 70px;
  }
  .btn::before, .btn::after {
    width: calc(100% - 20px);
  }
}

@media screen and (min-width: 380px) and (max-width: 479px) {
  .job_item > h3 {
    font-size: 22px;
    height: 1.75em;
    letter-spacing: calc(22px*0.3);
  }
  .job_item > p, .btn::before, .btn::after {
    font-size: 1em;
    letter-spacing: calc(1em*0.3);
  }
}

@media screen and (min-width: 380px) and (max-width: 479px) and (max-width: 290px) {
  .job_item > p, .btn::before, .btn::after {
    font-size: 0.8em;
    letter-spacing: calc(0.8em*0.3);
  }
}

@media screen and (min-width: 380px) and (max-width: 479px) {
  .job_category > li {
    width: 100%;
  }
  .btn, .btn::before, .btn::after {
    width: 329px;
    height: 70px;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .job_category > li {
    width: 100%;
  }
  .btn, .btn::before, .btn::after {
    width: 329px;
    height: 70px;
  }
  .job_item > p, .btn::before, .btn::after {
    font-size: 1.1em;
    letter-spacing: calc(1.1em*0.3);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .job_category > li {
    width: 100%;
  }
  .btn, .btn::before, .btn::after {
    width: 329px;
    height: 70px;
  }
  .job_item > p, .btn::before, .btn::after {
    font-size: 1.125em;
    letter-spacing: calc(1.125em*0.3);
  }
}

@media screen and (min-width: 1024px) {
  .job_category > li {
    width: 50%;
  }
  .job_item > p {
    width: 100%;
    margin: 0;
  }
  .btn {
    width: 329px;
    height: 70px;
  }
  .btn::before, .btn::after {
    width: 329px;
    height: 70px;
  }
  .job_item > p, .btn::before, .btn::after {
    font-size: 1.125em;
    letter-spacing: calc(1.125em*0.3);
  }
}

/***************** none animation for Safari *******************/
_::-webkit-full-page-media, _:future, :root .btn:hover::after, .btn:hover::before {
  transform: none;
}

_::-webkit-full-page-media, _:future, :root .btn::before, .btn::after {
  transition: none;
}

/**
 * helper class
 */
.is-hidden {
  display: none !important;
}

.ov-hidden {
  overflow: hidden;
}

.hide-on-mobile {
  display: block !important;
}

@media screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}

.hide-on-desktop {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .hide-on-desktop {
    display: block !important;
  }
}

@media screen and (max-width: 767px) {
  .hide-on-mobile-cell {
    display: none !important;
  }
}

.hide-on-mobile-br {
  display: block !important;
  line-height: 0;
}

@media screen and (max-width: 767px) {
  .hide-on-mobile-br {
    display: none !important;
  }
}

.hide-on-desktop-br {
  display: none !important;
  line-height: 0;
}

@media screen and (max-width: 767px) {
  .hide-on-desktop-br {
    display: block !important;
  }
}

.hide-on-desktop-br br {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .hide-on-desktop-br br {
    display: block !important;
  }
}

br.hide-on-mobile {
  display: block !important;
  line-height: 0;
}

@media screen and (max-width: 767px) {
  br.hide-on-mobile {
    display: none !important;
  }
}

br.hide-on-desktop {
  display: none !important;
  line-height: 0;
}

@media screen and (max-width: 767px) {
  br.hide-on-desktop {
    display: block !important;
  }
}

.hide-on-mobile-inline-block {
  display: inline-block !important;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .hide-on-mobile-inline-block {
    display: none !important;
  }
}

.hide-on-desktop-inline-block {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .hide-on-desktop-inline-block {
    display: inline-block !important;
    line-height: 1;
  }
}

.border-b-none {
  border-bottom: none !important;
}

@media screen and (min-width: 768px) {
  .border-b-none--pc {
    border-bottom: none !important;
  }
}

@media screen and (max-width: 767px) {
  .border-b-none--sp {
    border-bottom: none !important;
  }
  .border-t-none--sp {
    border-top: none !important;
  }
}

/* Font size
========================================================================== */
/* Relative size */
.size-xsmall {
  font-size: 0.9rem !important;
}

.size-small {
  font-size: 1rem !important;
}

.size-medium {
  font-size: 1.2rem !important;
}

.size-medium-ex {
  font-size: 1.3rem !important;
}

.size-large {
  font-size: 1.4rem !important;
}

.size-xlarge {
  font-size: 1.6rem !important;
}

.size-xxlarge {
  font-size: 1.8rem !important;
}

@media screen and (min-width: 768px) {
  .size-xxlarge-pc {
    font-size: 1.8rem !important;
  }
}

.size-xxxlarge {
  font-size: 2rem !important;
}

/* Absolute size */
@media screen and (max-width: 767px) {
  .size-14px-sp {
    font-size: 1.4rem !important;
  }
}

/* Display
========================================================================== */
.dn {
  display: none !important;
}

.db {
  display: block !important;
}

.di {
  display: inline !important;
}

.display-block {
  display: block !important;
}

.ib {
  display: inline-block !important;
}

.block {
  display: block !important;
}

.dt {
  display: table !important;
}

.dt-c {
  display: table-cell !important;
}

/* Font
========================================================================== */
.weight-normal {
  font-weight: normal !important;
}

.weight-bold {
  font-weight: bold !important;
}

.style-lined {
  text-decoration: underline !important;
}

.color-strong {
  color: #e90000 !important;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.letter-1 {
  letter-spacing: -1px;
}

.letter-2 {
  letter-spacing: -2px;
}

.letter-9 {
  letter-spacing: -9px;
}

.letter-0 {
  letter-spacing: 0 !important;
}

@media screen and (min-width: 768px) {
  .letter-0-pc {
    letter-spacing: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .letter-0-sp {
    letter-spacing: 0 !important;
  }
}

.letter1 {
  letter-spacing: 1px;
}

@media screen and (max-width: 767px) {
  .letter01em-sp {
    letter-spacing: .1em;
  }
}

/* Float
========================================================================== */
.pull-none {
  float: none !important;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.clear-both {
  clear: both !important;
}

.clear-left {
  clear: left !important;
}

.clear-right {
  clear: right !important;
}

.clearfix {
  zoom: 1;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/* Align
========================================================================== */
/* Horizontal */
.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.align-right {
  text-align: right !important;
}

/* Vertical */
.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-left-pc {
  text-align: left !important;
}

@media screen and (max-width: 767px) {
  .align-left-pc {
    text-align: inherit !important;
  }
}

.align-center-pc {
  text-align: center !important;
}

@media screen and (max-width: 767px) {
  .align-center-pc {
    text-align: inherit !important;
  }
}

@media screen and (max-width: 767px) {
  .align-left-sp {
    text-align: left !important;
  }
  .align-center-sp {
    text-align: center !important;
  }
}

/* Space
========================================================================== */
.space-normal {
  margin-bottom: 1rem !important;
}

.space-near {
  margin-bottom: 0.5rem !important;
}

.space-far {
  margin-bottom: 1.5rem !important;
}

.space-section {
  margin-bottom: 30px !important;
}

.space-module {
  margin-bottom: 25px !important;
}

.space-none {
  margin-bottom: 0 !important;
}

/* Margin
========================================================================== */
.mt-40 {
  margin-top: -40px !important;
}

.mt-30 {
  margin-top: -30px !important;
}

.mt-20 {
  margin-top: -20px !important;
}

.mt-15 {
  margin-top: -15px !important;
}

.mt-10 {
  margin-top: -10px !important;
}

.mt-5 {
  margin-top: -5px !important;
}

.mt-1 {
  margin-top: -1px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt100 {
  margin-top: 80px !important;
}

.mr-40 {
  margin-right: -40px !important;
}

.mr-30 {
  margin-right: -30px !important;
}

.mr-20 {
  margin-right: -20px !important;
}

.mr-15 {
  margin-right: -15px !important;
}

.mr-10 {
  margin-right: -10px !important;
}

.mr-8 {
  margin-right: -8px !important;
}

.mr-5 {
  margin-right: -5px !important;
}

.mr-1 {
  margin-right: -1px !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mb-40 {
  margin-bottom: -40px !important;
}

.mb-30 {
  margin-bottom: -30px !important;
}

.mb-20 {
  margin-bottom: -20px !important;
}

.mb-15 {
  margin-bottom: -15px !important;
}

.mb-10 {
  margin-bottom: -10px !important;
}

.mb-5 {
  margin-bottom: -5px !important;
}

.mb-1 {
  margin-bottom: -1px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.ml-40 {
  margin-left: -40px !important;
}

.ml-30 {
  margin-left: -30px !important;
}

.ml-20 {
  margin-left: -20px !important;
}

.ml-15 {
  margin-left: -15px !important;
}

.ml-10 {
  margin-left: -10px !important;
}

.ml-8 {
  margin-left: -8px !important;
}

.ml-5 {
  margin-left: -5px !important;
}

.ml-1 {
  margin-left: -1px !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: 1px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mt-40 {
  margin-top: -40px !important;
}

.mt-30 {
  margin-top: -30px !important;
}

.mt-20 {
  margin-top: -20px !important;
}

.mt-15 {
  margin-top: -15px !important;
}

.mt-10 {
  margin-top: -10px !important;
}

.mt-5 {
  margin-top: -5px !important;
}

.mt-1 {
  margin-top: -1px !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

@media screen and (max-width: 767px) {
  .mt-40-sp {
    margin-top: -40px !important;
  }
  .mt-30-sp {
    margin-top: -30px !important;
  }
  .mt-20-sp {
    margin-top: -20px !important;
  }
  .mt-15-sp {
    margin-top: -15px !important;
  }
  .mt-10-sp {
    margin-top: -10px !important;
  }
  .mt-5-sp {
    margin-top: -5px !important;
  }
  .mt-1-sp {
    margin-top: -1px !important;
  }
  .mt0-sp {
    margin-top: 0 !important;
  }
  .mt1-sp {
    margin-top: 1px !important;
  }
  .mt2-sp {
    margin-top: 2px !important;
  }
  .mt3-sp {
    margin-top: 3px !important;
  }
  .mt4-sp {
    margin-top: 4px !important;
  }
  .mt5-sp {
    margin-top: 5px !important;
  }
  .mt6-sp {
    margin-top: 6px !important;
  }
  .mt7-sp {
    margin-top: 7px !important;
  }
  .mt8-sp {
    margin-top: 8px !important;
  }
  .mt9-sp {
    margin-top: 9px !important;
  }
  .mt10-sp {
    margin-top: 10px !important;
  }
  .mt15-sp {
    margin-top: 15px !important;
  }
  .mt20-sp {
    margin-top: 20px !important;
  }
  .mt25-sp {
    margin-top: 25px !important;
  }
  .mt30-sp {
    margin-top: 30px !important;
  }
  .mt35-sp {
    margin-top: 35px !important;
  }
  .mt40-sp {
    margin-top: 40px !important;
  }
  .mt45-sp {
    margin-top: 45px !important;
  }
  .mt50-sp {
    margin-top: 50px !important;
  }
  .mt55-sp {
    margin-top: 55px !important;
  }
  .mt60-sp {
    margin-top: 60px !important;
  }
  .mr-40-sp {
    margin-right: -40px !important;
  }
  .mr-30-sp {
    margin-right: -30px !important;
  }
  .mr-20-sp {
    margin-right: -20px !important;
  }
  .mr-15-sp {
    margin-right: -15px !important;
  }
  .mr-10-sp {
    margin-right: -10px !important;
  }
  .mr-8-sp {
    margin-right: -8px !important;
  }
  .mr-5-sp {
    margin-right: -5px !important;
  }
  .mr-1-sp {
    margin-right: -1px !important;
  }
  .mr0-sp {
    margin-right: 0 !important;
  }
  .mr1-sp {
    margin-right: 1px !important;
  }
  .mr2-sp {
    margin-right: 2px !important;
  }
  .mr3-sp {
    margin-right: 3px !important;
  }
  .mr4-sp {
    margin-right: 4px !important;
  }
  .mr5-sp {
    margin-right: 5px !important;
  }
  .mr6-sp {
    margin-right: 6px !important;
  }
  .mr7-sp {
    margin-right: 7px !important;
  }
  .mr8-sp {
    margin-right: 8px !important;
  }
  .mr9-sp {
    margin-right: 9px !important;
  }
  .mr10-sp {
    margin-right: 10px !important;
  }
  .mr15-sp {
    margin-right: 15px !important;
  }
  .mr20-sp {
    margin-right: 20px !important;
  }
  .mr25-sp {
    margin-right: 25px !important;
  }
  .mr30-sp {
    margin-right: 30px !important;
  }
  .mr35-sp {
    margin-right: 35px !important;
  }
  .mr40-sp {
    margin-right: 40px !important;
  }
  .mr45-sp {
    margin-right: 45px !important;
  }
  .mr50-sp {
    margin-right: 50px !important;
  }
  .mr55-sp {
    margin-right: 55px !important;
  }
  .mr60-sp {
    margin-right: 60px !important;
  }
  .mb-40-sp {
    margin-bottom: -40px !important;
  }
  .mb-30-sp {
    margin-bottom: -30px !important;
  }
  .mb-20-sp {
    margin-bottom: -20px !important;
  }
  .mb-15-sp {
    margin-bottom: -15px !important;
  }
  .mb-10-sp {
    margin-bottom: -10px !important;
  }
  .mb-5-sp {
    margin-bottom: -5px !important;
  }
  .mb-1-sp {
    margin-bottom: -1px !important;
  }
  .mb0-sp {
    margin-bottom: 0 !important;
  }
  .mb1-sp {
    margin-bottom: 1px !important;
  }
  .mb2-sp {
    margin-bottom: 2px !important;
  }
  .mb3-sp {
    margin-bottom: 3px !important;
  }
  .mb4-sp {
    margin-bottom: 4px !important;
  }
  .mb5-sp {
    margin-bottom: 5px !important;
  }
  .mb6-sp {
    margin-bottom: 6px !important;
  }
  .mb7-sp {
    margin-bottom: 7px !important;
  }
  .mb8-sp {
    margin-bottom: 8px !important;
  }
  .mb9-sp {
    margin-bottom: 9px !important;
  }
  .mb10-sp {
    margin-bottom: 10px !important;
  }
  .mb15-sp {
    margin-bottom: 15px !important;
  }
  .mb20-sp {
    margin-bottom: 20px !important;
  }
  .mb25-sp {
    margin-bottom: 25px !important;
  }
  .mb30-sp {
    margin-bottom: 30px !important;
  }
  .mb35-sp {
    margin-bottom: 35px !important;
  }
  .mb40-sp {
    margin-bottom: 40px !important;
  }
  .mb45-sp {
    margin-bottom: 45px !important;
  }
  .mb50-sp {
    margin-bottom: 50px !important;
  }
  .mb55-sp {
    margin-bottom: 55px !important;
  }
  .mb60-sp {
    margin-bottom: 60px !important;
  }
  .ml-40-sp {
    margin-left: -40px !important;
  }
  .ml-30-sp {
    margin-left: -30px !important;
  }
  .ml-20-sp {
    margin-left: -20px !important;
  }
  .ml-15-sp {
    margin-left: -15px !important;
  }
  .ml-10-sp {
    margin-left: -10px !important;
  }
  .ml-8-sp {
    margin-left: -8px !important;
  }
  .ml-5-sp {
    margin-left: -5px !important;
  }
  .ml-1-sp {
    margin-left: -1px !important;
  }
  .ml0-sp {
    margin-left: 0 !important;
  }
  .ml1-sp {
    margin-left: 1px !important;
  }
  .ml2-sp {
    margin-left: 2px !important;
  }
  .ml3-sp {
    margin-left: 3px !important;
  }
  .ml4-sp {
    margin-left: 4px !important;
  }
  .ml5-sp {
    margin-left: 5px !important;
  }
  .ml6-sp {
    margin-left: 6px !important;
  }
  .ml7-sp {
    margin-left: 7px !important;
  }
  .ml8-sp {
    margin-left: 8px !important;
  }
  .ml9-sp {
    margin-left: 9px !important;
  }
  .ml10-sp {
    margin-left: 10px !important;
  }
  .ml15-sp {
    margin-left: 15px !important;
  }
  .ml20-sp {
    margin-left: 20px !important;
  }
  .ml25-sp {
    margin-left: 25px !important;
  }
  .ml30-sp {
    margin-left: 30px !important;
  }
  .ml35-sp {
    margin-left: 35px !important;
  }
  .ml40-sp {
    margin-left: 40px !important;
  }
  .ml45-sp {
    margin-left: 45px !important;
  }
  .ml50-sp {
    margin-left: 50px !important;
  }
  .ml55-sp {
    margin-left: 55px !important;
  }
  .ml60-sp {
    margin-left: 60px !important;
  }
  .mt-40-sp {
    margin-top: -40px !important;
  }
  .mt-30-sp {
    margin-top: -30px !important;
  }
  .mt-20-sp {
    margin-top: -20px !important;
  }
  .mt-15-sp {
    margin-top: -15px !important;
  }
  .mt-10-sp {
    margin-top: -10px !important;
  }
  .mt-5-sp {
    margin-top: -5px !important;
  }
  .mt-1-sp {
    margin-top: -1px !important;
  }
  .mt0-sp {
    margin-top: 0 !important;
  }
  .mt1-sp {
    margin-top: 1px !important;
  }
  .mt2-sp {
    margin-top: 2px !important;
  }
  .mt3-sp {
    margin-top: 3px !important;
  }
  .mt4-sp {
    margin-top: 4px !important;
  }
  .mt5-sp {
    margin-top: 5px !important;
  }
  .mt6-sp {
    margin-top: 6px !important;
  }
  .mt7-sp {
    margin-top: 7px !important;
  }
  .mt8-sp {
    margin-top: 8px !important;
  }
  .mt9-sp {
    margin-top: 9px !important;
  }
  .mt10-sp {
    margin-top: 10px !important;
  }
  .mt15-sp {
    margin-top: 15px !important;
  }
  .mt20-sp {
    margin-top: 20px !important;
  }
  .mt25-sp {
    margin-top: 25px !important;
  }
  .mt30-sp {
    margin-top: 30px !important;
  }
  .mt35-sp {
    margin-top: 35px !important;
  }
}

/* Padding
========================================================================== */
.pt0 {
  padding-top: 0 !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pl60 {
  padding-left: 60px !important;
}

@media screen and (max-width: 767px) {
  .pt0-sp {
    padding-top: 0 !important;
  }
  .pt5-sp {
    padding-top: 5px !important;
  }
  .pt10-sp {
    padding-top: 10px !important;
  }
  .pt15-sp {
    padding-top: 15px !important;
  }
  .pt20-sp {
    padding-top: 20px !important;
  }
  .pt25-sp {
    padding-top: 25px !important;
  }
  .pt30-sp {
    padding-top: 30px !important;
  }
  .pt35-sp {
    padding-top: 35px !important;
  }
  .pt40-sp {
    padding-top: 40px !important;
  }
  .pt45-sp {
    padding-top: 45px !important;
  }
  .pt50-sp {
    padding-top: 50px !important;
  }
  .pt55-sp {
    padding-top: 55px !important;
  }
  .pt60-sp {
    padding-top: 60px !important;
  }
  .pb0-sp {
    padding-bottom: 0 !important;
  }
  .pb10-sp {
    padding-bottom: 10px !important;
  }
  .pb15-sp {
    padding-bottom: 15px !important;
  }
  .pb20-sp {
    padding-bottom: 20px !important;
  }
  .pb25-sp {
    padding-bottom: 25px !important;
  }
  .pb30-sp {
    padding-bottom: 30px !important;
  }
  .pb35-sp {
    padding-bottom: 35px !important;
  }
  .pb40-sp {
    padding-bottom: 40px !important;
  }
  .pb45-sp {
    padding-bottom: 45px !important;
  }
  .pb50-sp {
    padding-bottom: 50px !important;
  }
  .pb55-sp {
    padding-bottom: 55px !important;
  }
  .pb60-sp {
    padding-bottom: 60px !important;
  }
  .pb80-sp {
    padding-bottom: 80px !important;
  }
  .pl10-sp {
    padding-left: 10px !important;
  }
  .pr10-sp {
    padding-right: 10px !important;
  }
}

/* width
========================================================================== */
.w100 {
  margin: 0 auto;
  width: 100px !important;
}

.w200 {
  margin: 0 auto;
  width: 200px !important;
}

.w10-per {
  margin: 0 auto;
  width: 10% !important;
}

.w15-per {
  margin: 0 auto;
  width: 15% !important;
}

.w20-per {
  margin: 0 auto;
  width: 20% !important;
}

.w25-per {
  margin: 0 auto;
  width: 25% !important;
}

.w30-per {
  margin: 0 auto;
  width: 30% !important;
}

.w33-per {
  margin: 0 auto;
  width: 33.333% !important;
}

.w40-per {
  margin: 0 auto;
  width: 40% !important;
}

.w45-per {
  margin: 0 auto;
  width: 45% !important;
}

.w50-per {
  margin: 0 auto;
  width: 50% !important;
}

.w55-per {
  margin: 0 auto;
  width: 55% !important;
}

.w60-per {
  margin: 0 auto;
  width: 60% !important;
}

.w65-per {
  margin: 0 auto;
  width: 65% !important;
}

.w70-per {
  margin: 0 auto;
  width: 70% !important;
}

.w75-per {
  margin: 0 auto;
  width: 75% !important;
}

.w80-per {
  margin: 0 auto;
  width: 80% !important;
}

.w85-per {
  margin: 0 auto;
  width: 85% !important;
}

.w90-per {
  margin: 0 auto;
  width: 90% !important;
}

.w95-per {
  margin: 0 auto;
  width: 95% !important;
}

.w100-per {
  margin: 0 auto;
  width: 100% !important;
}

@media screen and (max-width: 767px) {
  .w240px-sp {
    width: 240px !important;
  }
  .w100-sp {
    margin: 0 auto;
    width: 100px !important;
  }
  .w200-sp {
    margin: 0 auto;
    width: 200px !important;
  }
  .w300-sp {
    width: 300px;
  }
  .w10-per {
    margin: 0 auto;
    width: 10% !important;
  }
  .w15-per {
    margin: 0 auto;
    width: 15% !important;
  }
  .w20-per {
    margin: 0 auto;
    width: 20% !important;
  }
  .w25-per {
    margin: 0 auto;
    width: 25% !important;
  }
  .w30-per {
    margin: 0 auto;
    width: 30% !important;
  }
  .w33-per-sp {
    margin: 0 auto;
    width: 33.333% !important;
  }
  .w40-per-sp {
    margin: 0 auto;
    width: 40% !important;
  }
  .w45-per-sp {
    margin: 0 auto;
    width: 45% !important;
  }
  .w50-per-sp {
    margin: 0 auto;
    width: 50% !important;
  }
  .w55-per-sp {
    margin: 0 auto;
    width: 55% !important;
  }
  .w60-per-sp {
    margin: 0 auto;
    width: 60% !important;
  }
  .w65-per-sp {
    margin: 0 auto;
    width: 65% !important;
  }
  .w70-per-sp {
    margin: 0 auto;
    width: 70% !important;
  }
  .w75-per-sp {
    margin: 0 auto;
    width: 75% !important;
  }
  .w80-per-sp {
    margin: 0 auto;
    width: 80% !important;
  }
  .w85-per-sp {
    margin: 0 auto;
    width: 85% !important;
  }
  .w90-per-sp {
    margin: 0 auto;
    width: 90% !important;
  }
  .w95-per-sp {
    margin: 0 auto;
    width: 95% !important;
  }
  .w100-per-sp {
    margin: 0 auto;
    width: 100% !important;
  }
}
