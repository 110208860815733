@mixin clearfix {
	&:after {
	  content: "";
	  display: table;
	  clear: both;
	}
}
@mixin dotted($direction:left,$color:rgba(#000, 1.0), $stripe: 1, $spacing: 4, $height:1){
  background-size: ($stripe+$spacing)*1px ($stripe+$spacing)*1px;
  @if $direction == bottom {
    background-image: linear-gradient(to bottom, $color, $color ($stripe/($stripe+$spacing))*100%, transparent ($stripe/($stripe+$spacing))*100%, transparent 100%);
    height: 100%;
    width: $height*1px;

  } @else {
    background-image: linear-gradient(to right, $color, $color ($stripe/($stripe+$spacing))*100%, transparent ($stripe/($stripe+$spacing))*100%, transparent 100%);
    height: $height*1px;
    width: 100%;
  }
  border: none;
}
@font-face {
  font-family: "YuGothic M";
  src: local("Yu Gothic Medium");
}
@mixin font-hira {
  font-family: "ヒラギノ角ゴ ProN","Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo, sans-serif;
}
@mixin font-yugo {
  font-family: "游ゴシック体", YuGothic, "YuGothic M", sans-serif, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo;
}
@mixin font-eng {
  font-family: "Libre Baskerville", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝",'MS PMincho',serif;
}
@mixin font-mincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝",'MS PMincho',serif;
}
@mixin font-mincho-hira {
  font-family: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "游明朝", YuMincho, serif;
}
@mixin font-meirio {
  font-family: "ＭＳ Ｐゴシック", "MS PGothic", "メイリオ", Meiryo, sans-serif, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro";
}
@mixin media-query-spfirst($breakpoint) {
	@if map-has-key($breakpoints-spfirst, $breakpoint) {
		@media #{unquote(map-get($breakpoints-spfirst, $breakpoint))} {
			@content;
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+ "Available breakpoints are: #{map-keys($breakpoints-spfirst)}.";
	}
}
@mixin media-query-pcfirst($breakpoint) {
	@if map-has-key($breakpoints-pcfirst, $breakpoint) {
		@media #{unquote(map-get($breakpoints-pcfirst, $breakpoint))} {
			@content;
		}
	}
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+ "Available breakpoints are: #{map-keys($breakpoints-pcfirst)}.";
	}
}
@mixin media-query-pc {
	@include media-query-spfirst(sm) {
		@content;
	}
}
@mixin media-query-sp {
	@include media-query-pcfirst(sm) {
		@content;
	}
}
@mixin media-query-tab {
	@include media-query-pcfirst(lg) {
		@content;
	}
}
// 1. @keyframesを生成する@mixin
@mixin keyframes2($animation-name: animation, $beforeX: beforeX, $beforeY: beforeY, $afterX: afterX, $afterY: afterY) {
  @keyframes #{$animation-name} {
    @content;//ここに 3.の内容が入る
    0% {
      background-position: $beforeX $beforeY;
    }
    100% {
      background-position: $afterX $afterY;
    }
  }
}

@mixin keyframes($animation-name: animaton) {
  @keyframes #{$animation-name} {
    0% { background-position: 0 0;}
    100% { background-position: -180px 0;}
  }
}
